const lang = {
  "common.and": "and",
  "common.or": "or",
  "common.delete": "Delete",
  "common.loading": "Loading",
  "common.continue": "Continue",
  "common.done": "Done",
  "common.close": "Close",
  "common.accept": "Accept",
  "common.property": "Property",
  "common.status": "Status",
  "common.back": "Back",
  "common.pause": "Pause",
  "common.edit": "Edit",
  "common.connecting": "Connecting",
  "common.manage": "Manage",
  "common.active": "Active",
  "common.setup": "Set up",
  "common.connect": "Connect",
  "common.inactive": "Inactive",
  "common.activate": "Activate",
  "common.skip": "Skip",
  "common.cancel": "Cancel",
  "common.account": "Account",
  "common.file": "File",
  "common.call": "Call",
  "common.photo": "Photos",
  "common.runAi": "Run conversational",
  "common.remove": "Remove",
  "common.reset": "Reset",
  "common.clear": "Clear",
  "common.configure": "Configure",
  "common.confirmInactive": "Yes, inactive it",
  "common.confirmContinue": "Yes, continue",
  "common.confirmDelete": "Yes, delete it",
  "common.confirmScan": "Yes, scan it",
  "common.error": "Error",
  "common.tryItLater": "There was an error, try again later",
  "common.success": "Success",
  "common.today": "Today",
  "common.week": "This week",
  "common.7days": "Last 7 days",
  "common.30days": "Last 30 days",
  "common.thisMonth": "This month",
  "common.lastMonth": "Last month",
  "common.custom": "Custom",
  "common.date": "Date",
  "common.name": "Name",
  "common.message": "Message",
  "common.noResults":
    "There are no records to display for the selected date range",
  "common.results": "{total} records",
  "common.body": "Body",
  "common.tags": "Tags",
  "common.save": "Save",
  "common.saveClose": "Save and close",
  "common.submitChanges": "Submit changes",
  "common.updating": "Updating...",
  "common.newConversation": "New conversation",
  "common.create": "Create",
  "common.phone": "Phone number",
  "common.contactName": "Contact name",
  "common.optional": "optional",
  "common.signUp": "Sign up",
  "common.copyLink": "Link copied",
  "common.copyCode": "Code copied",
  "common.share": "Share",
  "common.embed": "Embed",
  "common.virtualAssistant": "Virtual Assistant",
  "common.scan": "Scan",
  "common.setUp": "Set up",
  "common.typeText": "Type some text...",
  "common.connected": "Connected",
  "common.searchText": "Search in text…",
  "common.characteres": "characters",
  "common.wordpress": "WordPress",
  "settings.pageTitle": "Settings",
  "settings.pageDescription": "Adjust your preferences",
  "settings.accountTitle": "Account",
  "settings.language": "Language",
  "settings.settingsTitle": "Settings",
  "settings.channels": "Channels",
  "settings.connect": "Connect",
  "settings.integrations": "Integrations",
  "settings.ai": "AI Settings",
  "settings.teams": "Team",
  "settings.payment": "Billing",
  "auth.login.title": "Welcome to Visito",
  "auth.login.email": "Email",
  "auth.login.password": "Password",
  "auth.login.login": "Log in",
  "auth.login.registerLabel": "New to Visito?",
  "auth.login.register": "Create an account",
  "auth.login.forgotPassword": "Click Here",
  "auth.login.forgotLabel": "Forgot Password?",
  "auth.register.title": " Get started for free",
  "auth.register.company": "Company name",
  "auth.register.web": "Website",
  "auth.register.email": "Email",
  "auth.register.password": "Password",
  "auth.register.register": "Register",
  "auth.register.loginLabel": "Already have an account?",
  "auth.register.login": "Sign in",
  "auth.register.loginTerms": "Terms of Service",
  "auth.register.loginPrivacy": "Privacy Policy",
  "auth.register.continuing": "By continuing, you agree to our",
  "auth.register.titleCloudbeds": "Create your Visito account",
  "auth.registerUser.title": "Set up your new account",
  "auth.registerUser.subtitle": "Please set up your password to get started.",
  "auth.forgotPassword.title": "Recover password",
  "auth.forgotPassword.description":
    "Enter the email address associated with your account, and we'll send you a link to reset your password.",

  "auth.forgotPassword.email": "Email",
  "auth.forgotPassword.submit": "Submit",
  "auth.forgotPassword.success":
    "A link to reset your password has been sent to your email",
  "auth.forgotPassword.error": "Email not found. Please verify and try again.",
  "auth.forgtoPassword.description":
    "Enter the email address associated with your account and we will send you a link to reset your password",
  "auth.resetPassword.title": "Reset Your Password",
  "auth.resetPassword.password": "New Password",
  "auth.resetPassword.submit": "Reset",
  "auth.resetPassword.cancel": "Cancel",
  "auth.resetPassword.success":
    "Your password has been successfully reset. You can now log in to your account using your new password.",
  "auth.resetPassword.description": "Please choose a new password below.",
  "auth.userInvite.success": "Invitation successfully accepted",
  "esc.needsReview": "Review",
  "esc.escalation": "Review",
  "esc.clickToView": "Click to view",
  "esc.tapToView": "Tap to view",
  "esc.solve": "Resolve escalation",
  "nav.inbox": "Inbox",
  "nav.integrations": "Integrations",
  "nav.home": "Home",
  "nav.outbound": "Events",
  "nav.ai": "AI",
  "nav.crm": "CRM",
  "nav.settings": "Settings",
  "nav.logout": "Log out",
  "nav.trial":
    "Your free trial ends in {count, plural, one {# day} other {# days}}",
  "nav.payment": "You have no more messages available.",
  "nav.paymentCTA": "Increase limit",
  "nav.pastDue": "Your payment failed.",
  "nav.pastDueCTA": "Update your payment method to keep your account active.",
  "nav.accountList": "Accounts",
  "nav.createAccount": "Create new account",
  "nav.helpCenter": "Help Center",
  "nav.connect": "Connect",
  "nav.helpChat": "Chat with us",
  "ai-settings.workingHours": "Working hours",
  "ai-settings.workingHoursDescription":
    "If an escalation occurs outside of working hours, the AI will inform the guest of the working hours.",
  "ai-settings.title": "AI - Settings",
  "ai-settings.paneTitle": "AI Configuration",
  "ai-settings.notifications": "Notifications",
  "ai-settings.notificationsDescription":
    "Download the Visito App and get push notifications for unresolved conversations.",
  "ai-settings-intructions.title": "Ajustes",

  "ai.pageTitle": "Artificial Intelligence",
  "ai.pageDescription": "Fine-tune and experiment with your AI agent",
  "ai.contextTitle": "Knowledge Base",
  "ai.contextDescription": "Manage your knowledge to train your AI agent",
  "ai.playgroundTitle": "AI Agent Sandbox",
  "ai.playgroundDescription":
    "Interact with your AI agent to observe its responses",

  "ai.agent": "Your AI Agent",
  "ai.agent.update": "Apply Changes",
  "ai.playground": "Playground",
  "ai.playground.desc": "Test your AI agent in a sample chat",
  "ai.playground.test": "Test Your AI",
  "ai.sources": "Sources",
  "ai.sources.alert": "Enhance your AI with the right data",
  "ai.sources.alert.desc":
    "Your AI performs best when it has information to learn from. To get the most relevant responses, you need to add at least one data source",
  "ai.sources.alert.button": "Got it",
  "ai.sources.desc": "Add data sources to improve your AI agent's accuracy",
  "ai.sources.website": "Website",
  "ai.sources.website.desc":
    "Scan a webpage to extract its content and use it to improve your AI",
  "ai.sources.website.scanning": "Website analysis in progress",
  "ai.sources.website.completed": "Website analysis completed",
  "ai.sources.website.rescan.confirmation":
    "Are you sure you want to rescan this website?",
  "ai.sources.website.rescan.confirmationDesc":
    "Rescanning this website will delete all previously extracted content and replace it with new data from the latest scan",
  "ai.sources.website.delete.confirmation":
    "Are you sure you want to delete this URL?",
  "ai.sources.website.delete.confirmationDesc":
    "Deleting this URL will remove its content from your AI agent's knowledge base. It will no longer be used to generate responses",
  "ai.sources.website.fullCrawl":
    "This will crawl all the links starting with the above URL",
  "ai.sources.website.singleCrawl":
    "This will extract only the content from the specific URL above",
  "ai.sources.website.max":
    "You've reached the limit of {limit} active URLs. Please delete or deactivate one to add another",
  "ai.sources.text": "Text",
  "ai.sources.text.desc":
    "Manually add information to help your AI generate better responses",
  "ai.sources.files": "Files",
  "ai.sources.files.desc":
    "Upload a file or document to provide your AI with useful information",
  "ai.sources.files.upload": "Upload File",
  "ai.sources.files.upload.label": "Upload an Image, PDF or CSV file",
  "ai.sources.files.delete": "Delete File",
  "ai.sources.files.delete.confirmation":
    "Are you sure you want to delete this file?",
  "ai.sources.files.delete.confirmationDesc":
    "Deleting this file will remove it from your AI agent's knowledge base. It will no longer be used to generate responses",
  "ai.sources.files.analyzed": "Analyzed Files",
  "ai.sources.files.limit": "The file is too big. Limit: 15MB",
  "ai.sources.files.max":
    "The limit of active files is {limit}. Please delete or deactivate one to upload a new file",
  "ai.sources.providers": "Providers",
  "ai.sources.providers.desc":
    "Connect third-party providers to give your AI access to external data and improve its responses",
  "ai.sources.providers.manage":
    "Manage your connected providers from the integrations page. You can add, update, or remove them anytime.",
  "ai.sources.providers.empty":
    "No providers connected yet. Link one from the Integrations page to start using external data",
  "ai.sources.providers.setUp": "Set Up a Provider",
  "ai.sources.customerContext": "Customer Context",
  "ai.sources.customerContext.desc":
    "Add information that will only be included in responses when a booking or order is confirmed. Use this to provide details like room numbers, Wi-Fi passwords, or special access codes",
  "ai.settings": "Settings",
  "ai.settings.desc": "Customize your AI agent's behavior and personality",
  "ai.settings.instructions": "AI Behavior",
  "ai.settings.instructions.desc":
    "Use instructions to define your AI agent's personality and style. These settings adjust its behavior but do not add knowledge sources. Be clear and specific to align responses with your needs",

  aiSettingsWorking: "We are preparing your business information",
  aiSettingsOptimizing: "We are optimizing your business information",
  aiSettingsOptimizingDesc:
    "We are optimizing the information extracted from your website",
  aiSettingsKnowledge: "AI Learning Hub",
  aiSettingsKnowledgeDesc:
    "Here, you can provide information to train the AI. Test the AI on the right to see what your customers will experience.",
  aiSettingsAlert:
    "Here, you can provide information to train the AI. Test the AI on the right to see what your customers will experience.",
  aiSettingsFreeText: "Free Text",

  aiSettingsReset: "Reset",
  aiSettingsCall: "Call",
  aiSettingsCalling: "Calling",
  aiSettingsCallConnected: "Connected",

  aiSettingsHangup: "Hang up",
  aiSettingsAssitant: "Meet Lana! Your virtual assistant",
  aiSettingsAssitantDesc:
    "This is where you can experiment and interact with Lana to understand exactly what your customers will experience from the AI's responses.",
  aiSettingsSaving: "Saving changes...",
  aiExceededLength: "You exceeded the content length",
  aiCompanyTitle: "{company}",
  aiPoweredBy: "Powered by",
  aiCompanyWelcomeTitle: "Welcome to {company} virtual assistant",
  aiCompanyFormDesc: "Enter your name",
  aiCompanyExplore: "Continue",
  aiShare: "Share your virtual assitant",
  aiCrawl: "Let's fetch your website and teach your AI Agent!",
  aiCrawling: "Your AI is crawling the website",
  aiCrawlingWebsiteSucc: "Website analysis completed",
  aiCrawlingSuccess: "Success! Your website was crawled",
  aiCrawlingSuccessDesc:
    "Your information has been fetched and ready for your review",
  aiCrawlingFailed:
    "There was a problem analyzing your website, please try again later",
  aiCrawlingSkip: "Do you want to continue without analyzing your website?",
  aiCrawlingSkipDesc:
    "Analyzing your website will help your AI assistant deliver better results.",
  aiReview: "Let's review",
  aiAgent: "AI Agent",
  aiAgentCreated: "Your AI agent is created",
  aiUpdateCta: "Edit",
  aiUpdateSucces: "Your AI agent has been updated",
  aiGeneralContext: "General Information",
  aiProviderContext: "Provider Information",

  "account.successAdded":
    "The invitation has been accepted, and you now have access to the account.",
  "account.acceptInvitation.title": "Accept Invitations",
  "account.acceptInvitation.pageDescription":
    "Please accept your pending invitations.",
  "account.acceptInvitation.account": "Account name",
  "account.acceptInvitation.noPending":
    "You don't have any pending invitations...",

  "account.createAccount.title": "Create a new account",
  "account.createAccount.pageDescription": "Enter the new account company name",

  "channels.title": "Channels",
  "channels.account": "Account",
  "channels.autoReplies": "AI auto replies",
  "channels.waTitle": "Connect your business WhatsApp numbers",
  "channels.waDescription":
    " Automate whatsapp convesations or manually respond in live Chat",
  "channels.igTitle": "Connect your instagram account",
  "channels.igDescription":
    "Automate inbox messages or manually respond in live chat.",
  "channels.fmTitle": " Connect facebook messenger",
  "channels.fmDescription":
    "Automate messenger chats or manually respond using messenger app.",
  "chats.aiAuto": "AI auto-reply",
  "chats.markAsOpen": "Mark as review",
  "chats.markAsClose": "Mark as resolved",
  "chats.accounts": "Accounts",
  "chats.all": "All",
  "chats.isGuest": "Guest",
  "chats.isBook": "Booking Offered",
  "chats.isManual": "Manual Conversation",
  "chats.isConversion": "Conversion",
  "chats.unblockContact": "Unblock Contact",
  "chats.blockContact": "Block Contact",
  "chats.contactOptedOut": "El contacto se ha dado de baja",
  "chats.manual": "Manual",
  "chats.blocked": "Blocked",
  "chats.reply": "Reply",
  "chats.filter": "Filter",
  "chats.escalated": "Review",
  "contactDetail.title": "Contact Profile",
  "contactDetail.actions": "Actions",
  "contactDetail.bookings": "Bookings",
  "contactDetail.followers": "followers",
  "chats.newMessageInput": "Enter Message...",
  "chats.searchContact": "Search Contact...",
  "chats.emptyStateTitle": "No conversations yet",
  "chats.emptyStateSubtitle": "Your chat list awaits your first message",
  "chats.emptyStateEscTitle": "No conversations under review",
  "chats.emptyStateEscSubtitle": "Seems like everything's in order!",
  "chats.emptyWelcomeTitle": "No conversation selected",
  "chats.emptyWelcomeSubtitle":
    " Choose a conversation from the left to view its messages.",
  "chats.searchNoResults": "No results found",
  "chats.searchStart": "Start typing to search",
  "interactive.call.summary": "Call summary",
  "interactive.call.active": "Call active",
  "integrations.title": "Integrations",

  "integrations.waSettings": "WhatsApp Settings",
  "integrations.waPaymentMethod": "Set Up Payment",

  "integrations.deleteTitle":
    "Are you sure you want to delete this integration?",
  "integrations.deleteDesc":
    "This action is irreversible. The integration will stop working immediately, and you’ll need to reconnect it if you want to use it again.",
  "integrations.alert.title": "Not available in your plan",
  "integrations.alert.description":
    "Your current plan doesn't include access to this channel or integration. To enable it, please upgrade your plan.",
  "integrations.alert.billing": "Upgrade plan",
  "integrations.alert.dismiss": "Got it",

  "integrations.alert.wa.title": "Connecting WhatsApp can be tricky",
  "integrations.alert.wa.description":
    "Meta enforces strict rules when connecting a WhatsApp number. Common issues include number rejections, the need to delete it from the app, and unexpected blocks. We strongly recommend reading our step-by-step guide or booking a quick call with our team. You can also continue on your own if you prefer.",
  "integrations.alert.wa.guide": "Read our guide",
  "integrations.alert.wa.button": "Book onboarding call",
  "integrations.alert.wa.continue": "Continue anyway",

  "integrations.wordpress": "WordPress Plugin",
  "integrations.wordpressInstructions":
    "To use Visito AI on WordPress, follow these steps:",
  "integrations.wordpressStep1":
    "Download the Visito AI plugin (ZIP) from this link:",
  "integrations.wordpressStep2":
    "Go to Plugins > Add New > Upload Plugin in your WordPress admin.",
  "integrations.wordpressStep3":
    "Activate the plugin and paste your API key under Settings > Visito AI.",

  "integrations.webChat": "Share your AI agent",
  "integrations.webName": "Web Chat",
  "integrations.playName": "Playground Chat",
  "integrations.webChatDescription":
    "Copy the link below to share your AI agent",
  "integrations.embedChat": "Embed a chat bubble in your website",
  "integrations.embedChatDescription":
    "Copy the code below to embed your AI agent in your source code",
  "integrations.copyLink": "Copy",
  "integrations.visitLink": "Open",
  "integrations.propertiesListTitle": "Properties",
  "integrations.noProperties": "No Properties Found",

  "integrations.acceptNewIntegration":
    "Please accept new incoming integration from {provider}",
  "integrations.completedNewIntegration":
    "You have succesfully accepted the integration with {provider}",
  "integrations.completedDbIntegration": "Integration added successfully",

  "integrations.webChatName": "Web Chat",
  "integrations.fmName": "Messenger",
  "integrations.waName": "WhatsApp",
  "integrations.cbName": "Cloudbeds",
  "integrations.haName": "Hostaway",
  "integrations.igName": "Instagram",
  "integrations.smName": "SiteMinder",
  "integrations.gsName": "Guesty",
  "integrations.miraiName": "Mirai",
  "integrations.voiceName": "Voice",

  "integrations.manualReplies": "AI automation is paused.",
  "integrations.autoReplies": "AI automation is active. ",

  "integrations.pmsActive": "Access is active.",
  "integrations.pmsPaused": "Access is paused.",

  "integrations.igReconnect": "Add or remove accounts",
  "integrations.waReconnect": "Add or remove numbers",
  "integrations.fmReconnect": "Add or remove pages",
  "integrations.voiceReconnect": "Add or remove phones",

  "integrations.smReconnect": "Add new properties",
  "integrations.cbReconnect": "Add new properties",
  "integrations.gsReconnect": "Add new properties",
  "integrations.haReconnect": "Add new properties",

  "integrations.smAdd": "Add a new SiteMinder property",
  "integrations.gsAdd": "Add a new Guesty property",

  "integrations.guide": "Integration guide",
  "integrations.missingInformation":
    "Your integration is missing some required information. Please check the integration guide and click ‘Manage’ to complete your account settings.",

  "integrations.nameDb": "Name",
  "integrations.refreshTokenDb": "API Secret",
  "integrations.clientIdDb": "API Client ID",
  "integrations.propertyIdDb": "Property ID",
  "integrations.widgetIdDb": "Booking engine widget ID",
  "integrations.publisherDb": "Publisher",
  "integrations.currencyDb": "Currency",
  "integrations.countryCodeDb": "Select country",
  "integrations.phoneNumberDb": "Phone Number",

  "integrations.timezoneDb": "Timezone",
  "integrations.bookingEngineDb": "Booking Engine",

  "integrations.nameDbPlaceholder": "Hotel Santa Fe",
  "integrations.refreshTokenDbPlaceholder":
    "Your API secret from guesty API dashboard",
  "integrations.clientIdDbPlaceholder":
    "Your Client ID from guesty API dashboard",
  "integrations.propertyIdDbPlaceholder": "28093",
  "integrations.widgetIdDbPlaceholder": "santafedirect",
  "integrations.publisherDbPlaceholder": "littlehotelier",
  "integrations.currencyDbPlaceholder": "MXN",
  "integrations.countryCodeDbPlaceholder": "CO",
  "integrations.phoneNumberDbPlaceholder": "5585655477",

  "integrations.pageTitle": "Integrations",
  "integrations.pageConnectTitle": "Connect",

  "integrations.pageDescription":
    "Connect your assistant to key communication channels and data sources",

  "integrations.fmDescription": "Connect your Facebook pages",
  "integrations.waDescription": "Connect your Whatsapp number",
  "integrations.cbDescription": "Connect your Cloudbeds properties",
  "integrations.haDescription": "Connect your Hostaway properties",
  "integrations.igDescription": "Connect your Instagram accounts",
  "integrations.smDescription": "Connect your SiteMinder properties",
  "integrations.gsDescription": "Connect your Guesty properties",
  "integrations.miraiDescription": "Connect your Mirai Booking Engine",
  "integrations.voiceDescription": "Connect your Phone Number",

  "teams.title": "Team Management",
  "teams.subTitle": "Manage access for team members",
  "teams.selectorLabel": "Role",
  "teams.rol.admin": "Admin",
  "teams.rol.user": "User",
  "teams.emailLabel": "Email",
  "teams.button": "Invite",
  "teams.message.success": "An invitation link has been sent to your email.",
  "teams.inviteMember": "Invite member",
  "teams.passwordLabel": "Password",
  "teams.buttonAccept": "Accept",
  "teams.inviteInfo": "Accept invitation to Teams",
  "teams.modalMessageTitle.success": "Successful invitation",
  "teams.modalMessageTitle.error": "Error sending invitation",
  "teams.modal.button": "Close",
  "invitedList.Members": "Members",
  "invitedList.Invites":
    "📩 {count, plural, one {# invitation remanining} other {# invitations remanining}}",
  "invitedList.emailLabel": "Email",
  "invitedList.roleLabel": "Role",
  "invitedList.dateLabel": "Joined",
  "invitedList.title.deleteSuccess": "Delete user",
  "invitedList.message.deleteSuccess": "User deleted successfully",
  "invitedList.message.deleteError": "Error deleting user",
  "payment.title": "Billing",
  "payment.subTitle":
    "Manage your payment methods, subscriptions and billing details",
  "payment.monthly": "Monthly",
  "payment.annual": "Annual",
  "payment.currentPlan": "Current Plan",
  "payment.managePlan": "Manage Plan",
  "payment.billingEmail": "Billing Email",
  "language.title": "Language",
  "language.subTitle":
    "Select the language you will like to change the platform to",
  "language.selectorLabel": "Select Language",
  "language.english": "English",
  "language.spanish": "Español",
  "language.portuguese": "Português",
  "language.languages": "Languages",
  "language.en_US": "English",
  "language.es_MX": "Spanish",
  "language.pt_BR": "Portugues",

  "home.statsTitle": "Last 30 days stats",
  "home.planTitle": "Current plan stats",
  "home.pageTitle": "Hi",
  "home.pageDescription": "Welcome to your Visito dashboard",
  "home.skip": "Skip onboarding",

  "home.onboardingTitle": "Get Started",
  "home.onboardingDescription":
    "Complete the steps below to fully setup your account",

  "home.title_account": "Create your account",
  "home.description_account":
    "Manage all communications in a single Visito inbox",

  "home.title_location": "Connect a property",
  "home.description_location": "Integrate your property with Visito",

  "home.title_channel": "Connect a channel",
  "home.description_channel": "Connect a channel to start receiving messages",

  "home.title_wa": "Connect WhatsApp",
  "home.description_wa": "Reach over 2 billions customers globally",

  "home.title_fm": "Connect Facebook Messenger",
  "home.description_fm": "Boost support and bookings with Facebook Messenger",

  "home.title_ig": "Connect Instagram",
  "home.description_ig":
    "Connect your Instagram account inbox to start receiving messages",

  "home.messageLimit": "Message limit",
  "home.descriptionLimit":
    "{count, plural, one {# message} other {# messages}} available of {messageLimit, number}",
  "home.dateLimit": "Restarts at {date}",
  "home.increaseLimit": "Increase limit",

  "home.title_ai": "Configure your AI data sources",
  "home.description_ai": "Program your AI agent with the right information",
  "home.pendingItems": "pending items",
  "home.uniqueContacts": "Unique Contacts",
  "home.totalMessages": "Total Messages",
  "home.percentageAuto": "AI Resolutions (%)",
  "home.noStats":
    "It looks like there's no data yet. Please check back later or adjust your filters.",

  "outbound.event": "Event",
  "outbound.pageTitle": "Events",
  "outbound.pageDescription":
    "Enhance guest engagement with automated communication triggers throughout the reservation journey",
  "outbound.viewEvent": "View event",
  "outboud.messagesSent": "Messages sent in the last 30 days",
  "outbound.reservation":
    "After a guest completes their reservation, the guest receives a confirmation message with booking details.",
  "outbound.time_reservation": "Sent inmidiately",
  "outbound.arrival":
    "Before arrival, the guest receives a message with helpful information about their stay.",
  "outbound.time_arrival": "2 days before arrival",
  "outbound.stay":
    "After the guest checks in, the guest receives a message with special instructions and useful information",
  "outbound.time_stay": "Sent inmidiately after arrival",
  "outbound.after_departure":
    "After the guest checks out, the guest receives a thank-you message along with a feedback request",
  "outbound.time_after_departure": "1 day after departure",
  "outbound.inactiveTitle": "Are you sure you want to inactive this event?",
  "outbound.inactiveDesc":
    "Turning this event off will not trigger the responses to go out",
  "outbound.disabling": "Disabling event...",
  "outbound.cardTitle": "Outbound events",
  "outbound.recentActivity": "Recent Activity",
  "outbound.noActivity": "No activity",
  "outbound.missingChannel":
    "Oops! You haven't connected a WhatsApp number to your account yet.",
  "outbound.missingChannelDesc":
    "Connect your WhatsApp number to engage with your guests during reservations, arrivals, and after their departure.",
  "outbound.missingChannelCTA": "Click here to connect",
  "outbound.modifyTemplate": "Modify template",
  "outbound.templateTitle": "Edit {template} ",
  "outbound.templateLastUpdate":
    "Last modified: {date} (Modified {count, plural, one {# time} other {# times}} within 30 days )",
  "outbound.templateLangDesc":
    "Modify the message you want to send to guests in {lang}",
  "outbound.templatePreview": "Preview message",
  "outbound.templatePreviewDesc": "Below is what your message will look like",
  "outbound.templateVarsInfo":
    "You can need to make sure the tags are entered properly. Reference below for what tags you can use",
  "outbound.templateVardDesc": "Use these tags in the message body",
  "outbound.templateTag": "Tag",
  "outbound.templateFunction": "Function",
  "outbound.template_confirmationId": "Confirmation number",
  "outbound.template_username": "The full name of the contact",
  "outbound.template_checkin": "Guest check in date",
  "outbound.template_checkout": "Guest check out date",
  "outbound.template_status_PENDING":
    "New template changes are under review This may take up to 24 hours until the new changes are approved.You can't modify the submitted template until review is completed.",
  "outbound.template_status_REJECTED":
    "Your changes has been rejected because of the following reason: {reason}",
  "outbound.template_status_LESS_THAN_ONE_DAY":
    "Wait 24 hours from the last update to edit the template again",
  "outbound.templateReviewTitle":
    "Are you sure you want to submit these new changes for review?",
  "outbound.templateReviewDesc":
    "Submitting for review will take up to 24 hours to get approved. You will be locked out of making further changes.",
  "outbound.templateSend": "Yes, send for review",
  "outbound.templateSendForReview":
    "The template has been sent for review, the approval process may take up to 24 hours",
  "outbound.template_review_url": "Link for positive reviews", // Liga para reseñas positivas
  "outbound.template_review_url_Desc":
    "This link will be included if the review is positive",
  "outbound.template_review_url_Placeholder":
    "https://tripadvisor.com/review/example",
  "outbound.isLanguageActive": "Is Language Active?",

  "outbound.templateNoLang": "Language unavailable",
  "outbound.templateNoLangDescription": "Add this language to get started",
  "onboarding.getStarted": "Get Started",
  "onboarding.pageTite": "Onboarding",
  "onboarding.stepsTitle": "Pick a time to finish your Visito onboarding",
  "onboarding.step1": "Reviewing your property information and chat settings",
  "onboarding.step2":
    "Setting up your communication channels, including WhatsApp and Instagram",
  "onboarding.step3": "Overview of the account features and capabilities",
  "onboarding.step4":
    "Sending test messages and enabling Visito on your digital properties",
  "onboarding.step5": "Answer any other questions you may have!",

  "onboarding.businessInfo": "Your business details",
  "onboarding.businessInfoWorking":
    "Almost there! This should take less than one minute...",
  "onboarding.businessInfoDone": "You’re all set.",
  "onboarding.businessInfoContinue":
    "Review info and continue. You can always edit this later.",
  "onboarding.channelsDone": "You’re all set!",
  "onboarding.continueToDashboard": "Continue to dashboard",
  "onboarding.igConnect": "Automatically connect your account.",
  "onboarding.igConnected": "Your Instagram account has been connected.",

  "onboarding.waConnect": "Get started in minutes.",
  "onboarding.waConnected": "Your WhatsApp phone number has been connected.",
  "onboarding.channelsTitle": "Connect Instagram or WhatsApp to get started:",

  "newConv.Title": "Create a new conversation",
  "newConv.Desc":
    "Enter the Whatsapp number and message below to create a new conversation",
  "newConv.invalidPhone": "Looks like you have entered an incorrect number.",
  "newConv.success": "Message sent successfully",
  "newConv.invalidFile": "The file is invalid",
};

export default lang;
