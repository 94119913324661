import { useEffect, useState } from "react";
import { Location } from "history";
import {
  IonHeader,
  IonToolbar,
  isPlatform,
  IonPage,
  IonContent,
  IonTitle,
  useIonToast,
  IonButtons,
  IonBackButton,
  IonList,
} from "@ionic/react";

// Reac int
import { FormattedMessage, useIntl } from "react-intl";

// Types
import styles from "./styles.module.css";

// Styles
import headerStyles from "../../theme/header.module.css";

// Api
import Api from "../../api/integrations";

// Components
import SkeletonLoader from "../SkeletonLoader";
import IntegrationItem from "./integration-item";
import IntegrationDetails from "./integration-details";
import NewCodeIntegration from "./new-code-integration";
import NewDbConnection from "./new-database-connection";
import IntegrationsAlert from "./alert";
import IntegrationsWarning from "./warning";

// Other
import { deserialize } from "../../utils/serialize";
import { loadScript } from "../../utils/script";
import { Integration, Connection, apiResult } from "../../interfaces";
import { integrationList } from "./constants";

// Constants
import { CLOUDBEDS, META } from "../../config";
import WebChat from "./web-chat";

const isMobile = isPlatform("android") || isPlatform("ios");

interface AuthResponse {
  accessToken?: string;
  code?: string;
}

const Header: React.FC<{ id: string }> = ({ id }) => {
  return (
    <div>
      <h1 className={headerStyles.pageTitle}>
        <FormattedMessage id={id} />
      </h1>
      <h3 className={headerStyles.pageDescription}>
        <FormattedMessage id="integrations.pageDescription" />
      </h3>
    </div>
  );
};

const MobileHeader: React.FC<{ id: string }> = ({ id }) => {
  return (
    <div>
      <div className={headerStyles.header}>
        <h1 className={headerStyles.mobileTitle}>
          <FormattedMessage id={id} />
        </h1>
        <h3 className={headerStyles.mobileDescription}>
          <FormattedMessage id="integrations.pageDescription" />
        </h3>
      </div>
    </div>
  );
};

const MobileTitle = ({
  backButton,
  id,
}: {
  backButton: string;
  id: string;
}) => {
  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton defaultHref={`/settings`} text={backButton} />
        </IonButtons>
        <IonTitle>
          <FormattedMessage id={id} />
        </IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};

const integrationBody: Integration = {
  cssLogo: "",
  connected: false,
  enabled: false,
  accounts: [],
  key: "",
  type: "",
  fields: [],
  editFields: [],
};

const Integrations: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const [alert, showAlert] = useState(false);
  const [warning, showWarning] = useState(false);
  const [warnedIntegration, setWarnedIntegration] =
    useState<Integration | null>(null);
  const [show, showModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState("");
  const [present] = useIonToast();
  const intl = useIntl();
  const [selectedIntegration, setSelectedIntegration] =
    useState<Integration>(integrationBody);
  const [list, setList] = useState<Integration[]>(integrationList);

  // General Code Integration and provider name
  const [code, setCode] = useState("");
  const [provider, setProvider] = useState("");
  const [showNewIntegration, setShowNewIntegration] = useState(false);
  const [interationError, setIntegrationError] = useState("");
  const [integrationLoading, setIntegrationLoading] = useState(false);

  // General Database Connection
  const [showDbConnection, setShowDbConnection] = useState(false);
  const [dbError, setDbError] = useState("");
  const [dbLoading, setDbLoading] = useState(false);

  useEffect(() => {
    getIntegrations();
    loadScript("https://connect.facebook.net/en_US/sdk.js", () => {
      window.FB.init({
        appId: "516908693723488",
        cookie: true,
        xfbml: true,
        version: "v21.0",
      });
    });
  }, []);

  useEffect(() => {
    const { search } = props.location;
    const params = deserialize(search);
    const { code, provider } = params;
    if (code) {
      switch (provider) {
        case "ig":
          setCode(code);
          setProvider(provider);
          setShowNewIntegration(true);
          break;
        case "cb":
          setCode(code);
          setProvider(provider);
          setShowNewIntegration(true);
          break;
        default:
          console.log("No provider found");
      }
    }
  }, [props.location.search]);

  useEffect(() => {
    if (props.location.pathname === "/connect") {
      setMode("channel");
    } else {
      setMode("location");
    }
  }, [props.location.pathname]);

  const presentError = ({ message }: { message: string }) => {
    present({
      message,
      duration: 5500,
      color: "danger",
      position: "top",
    });
  };

  const presentSucess = ({ message }: { message: string }) => {
    present({
      message,
      duration: 6000,
      color: "success",
      position: "top",
    });
  };

  const getIntegrations = async (key?: string | null) => {
    setLoading(true);
    const { data, err } = await Api.list();
    setLoading(false);
    if (data) {
      const { integrations, available } = data;
      const newList = list
        .map((i) => {
          const backend = integrations.filter(
            (j: Integration) => j.key === i.key
          );

          const isConnected = backend.length > 0;
          const accounts = [...backend];
          const requiredKeys = i.editFields?.map((f) => f.key) || [];
          return {
            ...i,
            connected: isConnected,
            accounts,
            enabled: available?.includes(i.key),
            hasErrors: requiredKeys.length
              ? accounts.some(
                  (account) =>
                    !Object.keys(account).some((key) =>
                      requiredKeys.includes(key)
                    )
                )
              : false,
            errorMessage: accounts
              .filter((a) => a.errorMessage)
              .map((a) => a.errorMessage)
              .join("."),
          };
        })
        .sort((a, b) =>
          a.connected === b.connected ? 0 : a.connected ? -1 : 1
        );
      setList(newList);
      if (selectedIntegration.key) {
        const selected = newList.find((i) => i.key === selectedIntegration.key);
        setSelectedIntegration(selected || integrationBody);
      }

      if (key) {
        const findIntegration = newList.find((i) => i.key === key);
        if (findIntegration) {
          selectIntegration({ integration: findIntegration });
        }
      }
    }

    if (err) {
      return presentError({
        message:
          err[intl.locale] || intl.formatMessage({ id: "common.tryItLater" }),
      });
    }
  };

  const acceptNewCodeIntegration = async () => {
    setIntegrationLoading(true);
    setIntegrationError("");
    switch (provider) {
      case "ig":
        {
          const meta = { token: code, platform: "ig" };
          const { data, err } = await Api.connectMeta({ meta });
          if (err) {
            presentError({
              message:
                err[intl.locale] ||
                intl.formatMessage({ id: "common.tryItLater" }),
            });
          }
          if (data) {
            onWillDismiss();
            getIntegrations("ig");
            presentSucess({
              message: intl.formatMessage(
                { id: "integrations.completedNewIntegration" },
                { provider: "Instagram" }
              ),
            });
          }
        }
        break;
      case "cb":
        {
          const { data, err } = await Api.cloudbedsCode(code);
          if (err) {
            setIntegrationError(err.message);
          }
          if (data) {
            onWillDismiss();
            getIntegrations("cb");
            presentSucess({
              message: intl.formatMessage(
                { id: "integrations.completedNewIntegration" },
                { provider: "Cloudbeds" }
              ),
            });
          }
        }
        break;
      default:
        console.log("No provider found");
    }
    setIntegrationLoading(false);
  };

  const onDismissAlert = () => {
    showAlert(false);
  };

  const onDismissWarning = () => {
    showWarning(false);
  };

  const onWillDismiss = () => {
    showModal(false);
    setShowNewIntegration(false);
    setShowDbConnection(false);
  };

  const selectIntegration = ({ integration }: { integration: Integration }) => {
    setSelectedIntegration(integration);
    showModal(true);
  };

  const createChannels = async ({
    auth,
    config,
  }: {
    auth: AuthResponse;
    config: apiResult;
  }) => {
    setLoading(true);
    const { code, accessToken } = auth;
    const { type, platform } = config;
    const { data, err } = await Api.connectMeta({
      meta: {
        token: type === "user" ? accessToken : code,
        type,
        platform,
      },
    });
    if (err) {
      presentError({
        message:
          err[intl.locale] || intl.formatMessage({ id: "common.tryItLater" }),
      });
    }
    if (data) {
      presentSucess({
        message: intl.formatMessage({
          id: "integrations.completedDbIntegration",
        }),
      });
    }
    getIntegrations();
  };

  const toggleConnection = async ({
    connection,
    integration,
    action,
  }: {
    connection: Connection;
    integration: Integration;
    action: object;
  }) => {
    const updatedList = list.map((item) => {
      if (item.key !== integration.key) return item;
      const updatedAccounts = item.accounts.map((account) =>
        account.id === connection.id ? { ...account, ...action } : account
      );
      return { ...item, accounts: updatedAccounts };
    });

    setList(updatedList);

    const { err, data } = await Api.toggle({ connection, integration, action });

    if (data) {
      getIntegrations();
    }
    if (err) {
      console.log(err);
    }
  };

  const addDbIntegration = async ({
    integration,
    form,
  }: {
    integration: Integration;
    form: object;
  }) => {
    setDbLoading(true);
    const { data, err } = await Api.addDbIntegration({ integration, form });
    setDbLoading(false);
    if (data) {
      onWillDismiss();
      getIntegrations();
      presentSucess({
        message: intl.formatMessage({
          id: "integrations.completedDbIntegration",
        }),
      });
    }
    if (err) {
      console.log(err);
      presentError({
        message:
          err[intl.locale] || intl.formatMessage({ id: "common.tryItLater" }),
      });
      setDbError(err);
    }
  };

  const deleteIntegration = async ({
    integration,
    connection,
  }: {
    integration: Integration;
    connection: Connection;
  }) => {
    // Call the api to delte
    const { err, data } = await Api.deleteIntegration({
      integration,
      connection,
    });
    if (data) {
      presentSucess({
        message: intl.formatMessage({ id: "common.success" }),
      });
      getIntegrations();
    }
    if (err) {
      presentError({
        message: intl.formatMessage({ id: "common.tryItLater" }),
      });
    }
  };

  const connectIntegration = async ({
    integration,
    warn = true,
  }: {
    integration: Integration;
    warn?: boolean;
  }) => {
    setSelectedIntegration(integration);
    if (!integration.enabled) {
      onWillDismiss();
      return showAlert(true);
    }
    if (integration.key == "wa" && warn) {
      onWillDismiss();
      setWarnedIntegration(integration);
      return showWarning(true);
    }
    switch (integration.type) {
      case "channel":
        switch (integration.key) {
          case "fm":
            handleMetaNewConnection({
              id: "542214705425786",
              type: "system",
              platform: "fm",
            });
            break;
          case "wa":
            handleMetaNewConnection({
              id: "1389334938656203",
              type: "system",
              platform: "wa",
            });
            break;
          case "ig":
            window.location.href = META.INSTAGRAM_URL;
            break;
          case "voice":
            setSelectedIntegration(integration);
            setShowDbConnection(true);
            break;
        }
        break;
      case "location":
        switch (integration.key) {
          case "cb":
            window.open(CLOUDBEDS.INSTALL_URL, "_blank");
            break;
          case "ha":
          case "mirai":
            window.open(
              `https://wa.me/14154235220?text=I want to connect ${integration.key} to Visito AI`,
              "_blank"
            );
            break;
          case "sm":
          case "gs":
            setSelectedIntegration(integration);
            setShowDbConnection(true);
            break;
        }
    }
  };

  const handleMetaNewConnection = (config: apiResult) => {
    window.FB.login(
      (response: apiResult) => {
        console.log(response);
        if (
          response.status === "connected" ||
          (config.type === "system" &&
            response.status === "unknown" &&
            response.authResponse)
        ) {
          console.log("getting token after login", response.authResponse);
          createChannels({ auth: response.authResponse, config });
        }
      },
      {
        config_id: config.id,
        response_type: config.type === "user" ? "token" : "code",
        override_default_response_type: true,
      }
    );
  };

  return (
    <IonPage id="main-content">
      {isMobile && (
        <MobileTitle
          backButton={intl.formatMessage({ id: "common.back" })}
          id={
            mode === "channel"
              ? "integrations.pageConnectTitle"
              : "integrations.pageTitle"
          }
        />
      )}
      <IonContent
        {...(isMobile
          ? { fullscreen: true, color: "light", className: "ion-padding" }
          : {})}
      >
        <div className={isMobile ? "view-wrapper" : "page-wrapper container"}>
          {isMobile ? (
            <>
              <div className="view-pane">
                <MobileHeader
                  id={
                    mode === "channel"
                      ? "integrations.pageConnectTitle"
                      : "integrations.pageTitle"
                  }
                />
              </div>
              {loading ? (
                <SkeletonLoader rows={4} />
              ) : (
                <>
                  <IonList inset={true}>
                    {list
                      .filter((i) => i.type === mode)
                      .map((integration) => (
                        <IntegrationItem
                          key={integration.key}
                          integration={integration}
                          onSelect={() => selectIntegration({ integration })}
                          onConnect={() => connectIntegration({ integration })}
                          isMobile={isMobile}
                        />
                      ))}
                  </IonList>
                  {mode === "channel" && (
                    <WebChat
                      webchat={list.find((i) => i.type === "webchat")}
                      isMobile={true}
                    />
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <Header
                id={
                  mode === "channel"
                    ? "integrations.pageConnectTitle"
                    : "integrations.pageTitle"
                }
              />
              {loading ? (
                <SkeletonLoader rows={4} />
              ) : (
                <>
                  <div className={styles.integrationWebChat}>
                    <div className={styles.webChatItem}>
                      {mode === "channel" && (
                        <WebChat
                          webchat={list.find((i) => i.type === "webchat")}
                          isMobile={false}
                        />
                      )}
                    </div>
                  </div>
                  <div className={styles.integrationContainer}>
                    {list
                      .filter((i) => i.type === mode)
                      .map((integration) => (
                        <div
                          key={integration.key}
                          className={styles.integrationItem}
                        >
                          <IntegrationItem
                            integration={integration}
                            onSelect={() => selectIntegration({ integration })}
                            onConnect={() =>
                              connectIntegration({ integration })
                            }
                            isMobile={isMobile}
                          />
                        </div>
                      ))}
                  </div>
                </>
              )}
            </>
          )}
        </div>

        {/* Modals */}
        <NewCodeIntegration
          isMobile={isMobile}
          isOpen={showNewIntegration}
          create={() => acceptNewCodeIntegration()}
          error={interationError}
          loading={integrationLoading}
          onWillDismiss={onWillDismiss}
          provider={provider}
          integration={integrationList.find((i) => i.key === provider)}
        />

        <IntegrationsAlert
          show={alert}
          onWillDismiss={onDismissAlert}
          title="integrations.alert.title"
          description="integrations.alert.description"
        ></IntegrationsAlert>

        <IntegrationsWarning
          show={warning}
          onWillDismiss={onDismissWarning}
          onContinue={() =>
            warnedIntegration &&
            connectIntegration({
              integration: warnedIntegration,
              warn: false,
            })
          }
          title="integrations.alert.wa.title"
          description="integrations.alert.wa.description"
        ></IntegrationsWarning>

        <NewDbConnection
          isOpen={showDbConnection}
          onCreate={(form: object) =>
            addDbIntegration({ integration: selectedIntegration, form })
          }
          error={dbError}
          loading={dbLoading}
          onWillDismiss={onWillDismiss}
          integration={selectedIntegration}
          isMobile={isMobile}
        />

        <IntegrationDetails
          isMobile={isMobile}
          isOpen={show}
          onWillDismiss={onWillDismiss}
          integration={selectedIntegration}
          onReconnect={() =>
            connectIntegration({ integration: selectedIntegration })
          }
          onDelete={({
            integration,
            connection,
          }: {
            integration: Integration;
            connection: Connection;
          }) => deleteIntegration({ integration, connection })}
          toggle={({
            connection,
            action,
          }: {
            connection: Connection;
            action: object;
          }) =>
            toggleConnection({
              connection,
              integration: selectedIntegration,
              action,
            })
          }
        />
        {/* end Modals */}
        {/* Meta Scripts */}
      </IonContent>
    </IonPage>
  );
};

interface ILayoutProps {
  location: Location;
  mode: string;
}

export default Integrations;
