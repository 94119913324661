import { FormattedMessage } from "react-intl";

import styles from "./styles.module.css";
import { MouseEventHandler } from "react";
import { Link } from "react-router-dom";

const IntegrationsAlert: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const show = props.show ? "d-block" : "d-none";

  return (
    <div className={show}>
      <div className={styles.overlayContainer}>
        <div className={styles.overlayContent}>
          <div className="flex flex-column gap-2">
            <h3 className="mb-1 fs-5">
              <FormattedMessage id={props.title} />
            </h3>
            <p className="mb-0 fs-4 color-step-600 mb-2">
              <FormattedMessage id={props.description} />
            </p>
          </div>
          <Link
            className="btn btn-visito-primary mt-3"
            to="settings/payment"
            onClick={props.onWillDismiss}
          >
            <FormattedMessage id="integrations.alert.billing" />
          </Link>
          <button
            className="btn btn-visito-link mt-3"
            onClick={props.onWillDismiss}
          >
            <FormattedMessage id="integrations.alert.dismiss" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default IntegrationsAlert;

interface ILayoutProps {
  show: boolean;
  onWillDismiss: MouseEventHandler;
  title: string;
  description: string;
}
