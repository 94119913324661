// Reac int
import { FormattedMessage } from "react-intl";
import {
  IconCircleCheckFilled,
  IconBook2,
  IconSettingsCode,
} from "@tabler/icons-react";
import { IonItem, IonLabel } from "@ionic/react";

import { Integration } from "../../../interfaces";
import styles from "../styles.module.css";
import badgesStyles from "../../../theme/badges.module.css";

interface Properties {
  integration: Integration;
  onSelect: Function;
  onConnect: Function;
  isMobile: boolean;
}

const IntegrationItem = ({
  integration,
  onSelect,
  onConnect,
  isMobile,
}: Properties) => {
  const { key, cssLogo, connected, accounts, guide, hasErrors, errorMessage } =
    integration;

  return isMobile ? (
    <IonItem
      button
      onClick={() => (connected ? onSelect() : onConnect())}
      detail={true}
    >
      <div slot="start">
        <div className={`${styles.logoMobile} ${cssLogo}`}></div>
      </div>
      <IonLabel>
        <FormattedMessage id={`integrations.${key}Name`} />
      </IonLabel>
      <div slot="end">
        {connected ? (
          <div style={{ marginLeft: "auto" }}>
            <div className={badgesStyles.statusBadge}>
              <IconCircleCheckFilled
                className={badgesStyles.activeIcon}
                size={16}
              />{" "}
              {accounts?.length} Connected
            </div>
          </div>
        ) : null}
      </div>
    </IonItem>
  ) : (
    <div className="visito-card">
      <div className="visito-card-body">
        <div className={styles.titleDisplay}>
          <div className={`${styles.titleItem} ${styles.logo}`}>
            <div className={`${styles.logo} ${cssLogo}`}></div>
          </div>
          <h3 className="fs-3">
            <FormattedMessage id={`integrations.${key}Name`} />
          </h3>
          {connected ? (
            <div style={{ marginLeft: "auto" }}>
              <div className={badgesStyles.statusBadge}>
                <IconCircleCheckFilled
                  className={badgesStyles.activeIcon}
                  size={16}
                />{" "}
                {accounts?.length} Connected
              </div>
            </div>
          ) : null}
        </div>

        <p
          className="fs-3 mt-2 mb-1 color-step-600"
          style={{ textWrap: "nowrap" }}
        >
          <FormattedMessage id={`integrations.${key}Description`} />
        </p>
      </div>
      <div className="visito-card-footer">
        {hasErrors && (
          <p className="fs-1 mt-1 mb-3 color-danger">
            <FormattedMessage id="integrations.missingInformation" />
          </p>
        )}
        {errorMessage && (
          <p className="fs-1 mt-1 mb-3 color-danger">{errorMessage}</p>
        )}
        <div style={{ display: "flex", gap: "1em" }}>
          {connected ? (
            <button
              className="btn-visito-primary btn btn-sm"
              onClick={() => onSelect()}
            >
              <FormattedMessage id="common.manage" />
            </button>
          ) : (
            <button
              className="btn-visito-primary btn btn-sm"
              onClick={() => onConnect()}
            >
              <FormattedMessage id="common.connect" />
            </button>
          )}
          {guide ? (
            <a
              href={guide}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-sm btn-flex btn-visito-light"
            >
              <IconBook2 size={19} />
              <FormattedMessage id="integrations.guide" />
            </a>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default IntegrationItem;
