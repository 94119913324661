import { FormattedMessage } from "react-intl";

import styles from "./styles.module.css";
import { MouseEventHandler } from "react";

interface ILayoutProps {
  show: boolean;
  onWillDismiss: MouseEventHandler;
  onContinue: Function;
  title: string;
  description: string;
}

const IntegrationsWarning: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const show = props.show ? "d-block" : "d-none";

  return (
    <div className={show}>
      <div className={styles.overlayContainer}>
        <div className={styles.overlayContent}>
          <div className="flex flex-column gap-2">
            <h3 className="mb-0 fs-5 mb-2">
              <FormattedMessage id={props.title} />
            </h3>
            <p className="mb-0 fs-4 color-step-600">
              <FormattedMessage id={props.description} />
            </p>
          </div>
          <a
            className="btn btn-visito-link mb-4"
            onClick={props.onWillDismiss}
            rel="noopener noreferrer"
            href="https://docs.visitoai.com/Channels-integrations/WhatsApp"
            target="_blank"
          >
            <FormattedMessage id={"integrations.alert.wa.guide"} />
          </a>
          <a
            className="btn btn-visito-primary mb-3 mt-3"
            onClick={props.onWillDismiss}
            rel="noopener noreferrer"
            href="https://cal.com/team/visito/support"
            target="_blank"
          >
            <FormattedMessage id={"integrations.alert.wa.button"} />
          </a>
          <button
            className="btn btn-visito-light"
            onClick={(e) => {
              props.onContinue();
              props.onWillDismiss(e);
            }}
          >
            <FormattedMessage id="integrations.alert.wa.continue" />
          </button>
          <button
            className="btn btn-visito-link-light mt-3"
            onClick={props.onWillDismiss}
          >
            <FormattedMessage id="common.cancel" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default IntegrationsWarning;
